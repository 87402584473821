import React, {useEffect} from "react"

const Privacy = () => {
    useEffect(() => {
        
        if(typeof window !== 'undefined'){
            window.location.href = 'https://www.infeedo.ai/privacy-policy' + window.location.search; 
        }
    },[]);
};

export default Privacy;